@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  @apply bg-gray-100 text-gray-800;
  font-family: 'Inter', sans-serif;
}

th {
  @apply font-normal;
}
